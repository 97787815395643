.numbered-list {
  padding-top: 8svh;
  text-align: left;
  background-color: #f2f2f2;
  overflow: auto;
}
.nl-list {
  padding-bottom: 8svh;
}
.numbered-list h2 {
  padding-left: 16px;
}

.nl-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.nl-row-column {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.nl-row h2 {
  flex: 0 0 auto;
}

.nl-delete {
  color: red;
  border: none;
  background-color: #f2f2f2;
  margin-left: 0px;
}
.nl-signout {
  width: 15svw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 1px gray solid;
  color: gray;
  border: none;
  margin-right: 8px;
}

.nl-delete:hover {
  background-color: #f2f2f2;
  color: red;
}

.nl-delete:active {
  background-color: #f2f2f2;
  color: red;
}

.nl-delete span {
  padding-left: 8px;
}

/* Styles for devices with a maximum width of 767 pixels (typical for mobile devices) */
@media only screen and (max-width: 767px) {
  .nl-signout {
    width: 60svw;
  }
  /* Additional styles as needed for a mobile layout */
}
