/* GoogleMapComponent.css */
.gm-display-container {
  height: 15svh;
  padding: 0 16px;
}

.gm-display-content {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 1rem;
}

.map-container {
  width: 100vw;
}

.small {
  height: 77svh;
}
.large {
  height: 92svh;
}

.map-display-container {
  height: 92svh;
  position: fixed;
  top: 0;
}
