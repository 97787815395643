.address-display-container {
  background-color: #333333;
  position: absolute;
  bottom: 0;
  color: white;
  padding: 16px;
  text-align: center;
  border-radius: 5px;
  width: 80%;
  left: 50%;
  transform: translate(-50%, -10%);
  z-index: 1000;
}

.address-display-p {
  padding: 4px 8px;
  font-weight: bold;
  font-size: 1rem; /* Use rem or px for consistency */
}

.address-row {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.address-display-button-cancel,
.address-display-button {
  color: white;
  flex: 1;
  padding: 10px;
  margin: 5px 8px;
}

.address-display-button-cancel {
  background-color: red; /* Adjust colors as needed */
  border-color: red;
}

.address-display-button {
  background-color: green; /* Adjust colors as needed */
  border-color: green;
}

@media only screen and (max-width: 600px) {
  .address-display-container {
    padding: 12px; /* Adjust padding for smaller screens */
  }

  .address-display-p {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}
