.App {
  overflow: hidden;
  width: 100vw;
}

.address-display {
  position: absolute;
  bottom: 2%;
  width: 100%;
  display: flex;
  justify-content: center;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-map-button {
  height: 8svh;
  border: 1.5px solid green;
  border-radius: 0px;
  color: green;
  width: 100%;
  background-color: #fff;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-map-button:active {
  background-color: lightgreen;
  color: darkgreen;
}

.toggle-map-button-container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 8svh; /* Adjust this value based on your layout */
}

input {
  border-radius: 0;
}

.app-signout-btn {
  display: flex;
  padding-top: 16px;
  justify-content: center;
}
