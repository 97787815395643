.google-button-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f8f9fa;
}
.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-signin-button:hover {
  background-color: #f8f9fa; /* Optional: Change color on hover */
}

.google-icon {
  width: 18px; /* Adjust the size as needed */
  height: 18px;
  margin-right: 10px;
}

.button-text {
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: bold;
  color: #3c4043; /* Google's primary text color */
}
