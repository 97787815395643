.popup-menu {
  position: absolute;
  top: -50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element perfectly */
  background-color: #fff; /* Set your preferred background color */
  border: 1px solid #ccc; /* Add borders or other styles as needed */
  border-radius: 20px;
  padding: 10px;
  z-index: 1; /* Ensure the menu appears above other elements */
  width: 75%;
}

.popup-menu-row {
  display: flex;
  flex-direction: row;
}

.stop-item button {
  width: 100%;
  text-align: left;
  border: none;
  padding: 16px;
  border-radius: 0;
}

.stop-item {
  width: 100%;
  position: relative;
  margin-bottom: 1px; /* Use margin for separation */
}

.st-button {
  color: black;
  width: 100%;
  background-color: #f2f2f2;
}

.pu-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  color: gray;
  border: 1px lightcoral solid;
}

.pu-delete {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  color: red;
  border: 1px lightcoral solid;
}

.pu-delete:active {
  background-color: red;
  color: white;
}

.pu-cancel:active {
  background-color: gray;
  color: white;
}
